import { myCustomerActions } from ".";
import {
  createServiceCustomer,
  getAllServiceCustomer,
  updateServiceCustomer,
  getOneSelectedService,
  getOneCustomerByQueryService,
  updateIsDeleteService,
  getCustomerPuchased,
  getAllRepairService,
  getTopCustomerService,
  getCustomerService,
  getCustomerByDateService,
  getCustomerByPhoneNumberService,
  getCustomerInvoiceServices,
  getCustomerMetricsServices,
  getCustomerDevicesServices,
  getCustomerNotesServices,
  getCustomerNoSaleServices,
  customerValidationService,
} from "./services";
import { SessionExpired } from "../auth/AutoLogout";
import { notification } from "antd";

export const createCustomerAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));

      const res = await createServiceCustomer(data, token);
      if (res?.status === 201) {
        dispatch(myCustomerActions.setNew(res));
        dispatch(myCustomerActions.setIsFetching(false));
        return { type: true, data: res.data };
      } else if (res?.response?.status === 409) {
        notification.error({ message: res?.response?.data?.message });
        dispatch(myCustomerActions.setIsFetching(false));
        return { type: true, data: null };
      }
      dispatch(myCustomerActions.setIsFetching(false));
      return { type: true, data: null };
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCustomersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      dispatch(myCustomerActions.setAllIsFetching(true));
      const res = await getAllServiceCustomer(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setAll(res));
        dispatch(myCustomerActions.setIsFetching(false));
        dispatch(myCustomerActions.setAllIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
      dispatch(myCustomerActions.setAllIsFetching(false));
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerService(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setAllcustomer(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomersByDateAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerByDateService(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelectByDate(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTopCustomersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getTopCustomerService(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setTopcustomer(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneSelectedService(id, token);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelected(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerByQueryAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneCustomerByQueryService(query, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSearchResult(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCustomerAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));

      const res = await updateServiceCustomer(itemId, data, token);

      if (res?.status === 200) {
        // const resAll = await getAllServiceCustomer(token, "");
        dispatch(myCustomerActions.setUpdated(res));
        // dispatch(myCustomerActions.setAll(resAll));
        dispatch(myCustomerActions.setIsFetching(false));
        return true;
      } else if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      } else {
        dispatch(myCustomerActions.setIsFetching(false));
        return false;
      }
      // For Anauthorized Error
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteCustomerAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await updateIsDeleteService(itemId, data, token);

      // console.log("dispatching the action.....");
      // console.log(">>>>!!!>>>::deleted)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelected(res?.data));
        const resAll = await getAllServiceCustomer(token, "");
        dispatch(myCustomerActions.setAll(resAll));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const getCustomerPurchaseAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerPuchased(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setPurchased(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomerSubscriptionAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerPuchased(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerSubscription(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCustomerRepairsAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getAllRepairService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerRepair(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const searchCustomerByPhoneNumberAction = (
  token: string,
  query: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerByPhoneNumberService(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerByPhonenNumber(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerInvoiceAction = (
  token: string,
  query: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerInvoiceServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerInvoice(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerMetricsAction = ( id: string,token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerMetricsServices(id, token);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerMetrics(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerDevicesAction = (
  token: string,
  query: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerDevicesServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerDevices(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerNotesAction = (
  token: string,
  query: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerNotesServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerNotes(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerNoSaleAction = (
  token: string,
  query: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerNoSaleServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerNoSale(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const customerValidationAction = (id: any, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await customerValidationService(id, data, token);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setUserValidation(res));
        dispatch(myCustomerActions.setIsFetching(false));
        return { type: true, data: res?.data };
      } else {
        dispatch(myCustomerActions.setInvalidUserData(res?.response));
        dispatch(myCustomerActions.setIsFetching(false));
        return { type: false, data: res?.response };
      }
    } catch (err) {
      
    }
  };
};