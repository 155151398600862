import HeaderComponent from "../../../../components/HeaderComponent";
import { Stack } from "@mui/material";
import { Table, Spin, Space, Badge, Skeleton } from "antd";
import SendIcon from "@mui/icons-material/Send";
import { Link, useParams, useNavigate } from "react-router-dom";
import { searchValue } from "../../../../utils/setColor";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import DangerButton from "../../../../components/buttons/DangerButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {
  getOneSupplierQuotationRfqSent,
  getSelectedQuotationAction,
  sendEmailAction,
  updateQuotationAction,
  updateQuotationActionRfq,
} from "../../../../store/quotation/actions";
import { myQuotationActions } from "../../../../store/quotation";
import { quotationColumns } from "../../../../components/reUsableTable/colmns";
import { LoadingOutlined } from "@ant-design/icons";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const UpdateSaveButtons = (props: any) => {
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { rfqId } = useParams();
  const navigate = useNavigate();
  const handleSendEmail = async () => {
    auth?.token &&
      quotation?.allRfqSupplier?.data?.length > 0 &&
      sendEmailAction(auth.token, {
        quotationToSuppliers: quotation?.allRfqSupplier?.data?.map(
          (d: any) => d._id
        ),
      })(dispatch);
  };

  const handleCancelQuotation = async () => {
    if (auth?.token) {
      await updateQuotationAction(
        quotation?.selected?._id,
        { status: "Cancelled" },
        auth?.token
      )(dispatch);
      await updateQuotationActionRfq(rfqId as string, auth?.token, {
        status: "Cancelled",
      })(dispatch);
      navigate(-1);
    }
  };

  return (
    <div>
      {quotation?.selectedRfq?.status &&
        quotation?.selectedRfq?.status?.toLowerCase() !== "replied" && (
          <PrimaryButton
            onClick={async () => await handleSendEmail()}
            name="Re-send Email"
          />
        )}
      {quotation.isFetching ? (
        <Spin indicator={antIcon} style={{ width: 100 }} />
      ) : (
        quotation?.selectedRfq?.status &&
        quotation?.selectedRfq?.status?.toLowerCase() === "sent" && (
          <DangerButton
            onClick={async () => await handleCancelQuotation()}
            name="Cancel "
          />
        )
      )}
      {quotation?.selectedRfq?.status &&
        quotation?.selectedRfq?.status?.toLowerCase() === "sent" && (
          <Link
            to={`/reply/proforma/${quotation?.selectedRfq?._id}`}
            target="_blank"
          >
            <PrimaryButton
              // onClick={async () => ()}
              name="Manual Reply "
            />
          </Link>
        )}
      {!props.isCancel && props?.sendButtonDefault && (
        <LoadingButton
          loading={props.isLoading}
          loadingPosition="start"
          variant="contained"
          endIcon={<SendIcon />}
          color="success"
          onClick={() => props?.onClick()}
        >
          Send Quotation
        </LoadingButton>
      )}
    </div>
  );
};

const PageView = (_props: any) => {
  const { auth, quotation, company } = useSelector((state: any) => state);
  const [isCancel, setIsCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id, rfqId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(myQuotationActions.setNew(null));
    dispatch(myQuotationActions.setSelected(null));
    // console.log("this  is my RFQID:", rfqId);
    auth?.token &&
      getSelectedQuotationAction(auth?.token, `?quotationId=${id}`)(dispatch);
    auth?.token &&
      rfqId &&
      getOneSupplierQuotationRfqSent(rfqId, auth?.token)(dispatch);
  }, [id, auth?.token, rfqId, dispatch]);

  const data: {
    type: any;
    brand: any;
    model: any;
    specification: any;
    quantity: any;
    status: string;
  }[] = [];
  const values = quotation?.selected?.listOfProducts;

  values &&
    values?.forEach((el: any) => {
      data.push({
        type: el.product.model,
        brand: el.product.brand,
        model: el.product.model,
        specification: el.specification
          .map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        quantity: el.quantity,
        status: quotation.selected.status,
      });
    });

  const handleSendEmail = async () => {
    console.log(quotation.allRfqSupplier.data.map((d: any) => d._id));
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack spacing={1}>
      <div className="text-[#030229] opacity-70 pt-2">
        <DetailsHeaderActionBar
          pageName="Request For Quotation"
          title={id}
          goBack={goBack}
        />
      </div>
      {!rfqId && (
        <HeaderComponent
          title={`RFQ / ${id} `}
          filterCard={
            quotation?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
              />
            ) : (
              <></>
            )
          }
          isNotAddButton={true}
          isNotCollapse={true}
          goBack="/purchase/quotations"
          isOtherButton={true}
        />
      )}
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {quotation?.isFetching ? (
              <Spin size="small" />
            ) : (
              quotation?.selectedRfq?.status
            )}
          </span>
        }
        color={
          quotation?.isFetching
            ? "#F5F6FA"
            : searchValue(quotation?.selectedRfq?.status)
        }
        style={{
          width: "11rem",
          padding: "5px 1px",
          marginLeft: "3rem",
          marginTop: "1rem",
          transform: "rotate(20deg)",
          transformOrigin: "top-left",
        }}
      >
        <div className="min-h-[86vh] bg-white px-14 p-8">
          {rfqId && (
            <div className="w-full max-h-full bg-white flex flex-col gap-4 relative text-[#030229] pt-8 mt-6 mb-2">
              <h1 className="font-semibold text-[23px] text-[#030229] opacity-90">
                QUOTATION
              </h1>
              <div className="flex flex-col gap-1 absolute right-[21.8rem] top-14 mb-6">
                <Space>
                  <p className="text-[14px] font-medium">Date : </p>
                  <p className="text-[14px] font-normal">
                    {quotation?.selectedRfq?.createdAt?.slice(0, 10)}
                  </p>
                </Space>
                <Space>
                  <p className="text-[14px] font-medium">Quatation ID : </p>
                  <p className="text-[14px] font-normal">
                    {" "}
                    {quotation?.selected?.quotationId}
                  </p>
                </Space>
                <Space>
                  <p className="text-[14px] font-medium">Deadline : </p>
                  <p className="text-[14px] font-normal">
                    {" "}
                    {rfqId ? quotation?.selectedRfq?.confirmationDate : " "}
                  </p>
                </Space>
              </div>

              {quotation?.isFetching ? (
                <Skeleton />
              ) : (
                <div className="flex justify-between mt-20 mb-10 flex-wrap">
                  <div>
                    <div className="w-[550px] h-[3.5rem] bg-[#F5F6FA] font-semibold pt-4 pl-6 text-[16px]">
                      From
                    </div>
                    <div className="mt-6 font-medium flex flex-col gap-2 text-[15px] ml-2">
                      <p>
                        {company?.selected?.name?.toUpperCase() || "Samphone"}
                      </p>
                      <p>
                        {company?.selected?.address?.country || "Kigali-Rwanda"}
                        {company?.selected?.address?.provence}
                      </p>
                      <p>{company?.selected?.email}</p>
                      <p>{company?.selected?.phone}</p>
                    </div>
                  </div>
                  <div>
                    <div className="w-[550px] h-[3.5rem] bg-[#F5F6FA] font-semibold pt-4 pl-6 text-[16px]">
                      To
                    </div>
                    <div className="mt-6 font-medium flex flex-col gap-2 text-[15px] ml-2">
                      <p>
                        {quotation?.selectedRfq?.supplier?.name?.toUpperCase()}
                      </p>
                      <p>{quotation?.selectedRfq?.supplier?.address || "-"}</p>
                      <p>{quotation?.selectedRfq?.supplier?.email}</p>
                      <p>{quotation?.selectedRfq?.supplier?.phone}</p>
                    </div>
                  </div>
                </div>
              )}
              <Table columns={quotationColumns} dataSource={data} />
            </div>
          )}
          {(values?.length >= 1 &&
            quotation?.selectedRfq?.status?.toLowerCase() === "Replied") ||
            ("Sent" && rfqId && (
              <UpdateSaveButtons
                //  isCancel={isCancel}
                isLoading={isLoading}
                sendButtonDefault={false}
                onClick={() => {
                  setIsCancel(false);
                }}
                handleSendEmail={handleSendEmail}
              />
            ))}
        </div>
      </Badge.Ribbon>
    </Stack>
  );
};

export default PageView;
