import React from "react";
import OverViewCard from "./OverViewCard";
import { useSelector } from "react-redux";
import { calculatePercentageChange } from "../../../../utils/converter";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";
import { ReactComponent as TotalIcon } from "../../../../assets/icons/dashboard/channel/box-tick.svg";
import CustomButton from "../../../../components/buttons/CustomButton";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { ReactComponent as OverviewIcon } from "../../../../assets/icons/dashboard/Icon.svg";

export const TotalDevices = () => {
  const { overView } = useSelector((state: any) => state);

  const totalCurrentDevice = overView?.quantityRevenue?.data?.day2Quantity;
  const totalComparedDevice = overView?.quantityRevenue?.data?.day1Quantity;
  const percentageChange = calculatePercentageChange(
    totalCurrentDevice,
    totalComparedDevice
  );
  return (
    <OverViewCard
      title={"Total Device Financed"}
      amount={`${overView?.quantityRevenue?.data?.day2Quantity || 0} Pieces`}
      icon={<TotalIcon />}
      comparedAmount={`${
        overView?.quantityRevenue?.data?.day1Quantity || 0
      } Pieces`}
      parcent={
        <p
          className={`${
            percentageChange?.type === "increase"
              ? "text-[#57C38A]"
              : percentageChange?.type === "decrease"
              ? "text-[#F1595C]"
              : "text-[#605BFF]"
          }`}
        >
          {" "}
          {`${percentageChange?.percentage}%`}
        </p>
      }
      icon2={
        percentageChange?.type === "increase" ? (
          <FaArrowUpLong color="#57C38A" />
        ) : percentageChange?.type === "decrease" ? (
          <FaArrowDownLong color="#F1595C" />
        ) : (
          ""
        )
      }
      compareText={`${
        percentageChange?.type === "increase"
          ? "Increased"
          : percentageChange?.type === "decrease"
          ? "Decreased"
          : "Increased"
      }`}
      description={"All devices sold under device financing"}
    />
  );
};

export const TotalAmountPaid = () => {
  const { overView, company } = useSelector((state: any) => state);

  const totalCurrentAmount = overView?.quantityRevenue?.data?.day2Amount;
  const totalComparedAmount = overView?.quantityRevenue?.data?.day1Amount;
  const percentageChange = calculatePercentageChange(
    totalCurrentAmount,
    totalComparedAmount
  );
  return (
    <OverViewCard
      title={"Total Amount Paid"}
      amount={`${getDefaultCurrencyCode(company)} ${
        overView?.quantityRevenue?.data?.day2Amount || 0
      }`}
      icon={<OverviewIcon />}
      comparedAmount={`${getDefaultCurrencyCode(company)} ${
        overView?.quantityRevenue?.data?.day1Amount || 0
      }`}
      textColor={"[#F1595C]"}
      parcent={
        <p
          className={`${
            percentageChange?.type === "increase"
              ? "text-[#57C38A]"
              : percentageChange?.type === "decrease"
              ? "text-[#F1595C]"
              : "text-[#605BFF]"
          }`}
        >
          {" "}
          {`${percentageChange?.percentage}%`}
        </p>
      }
      icon2={
        percentageChange?.type === "increase" ? (
          <FaArrowUpLong color="#57C38A" />
        ) : percentageChange?.type === "decrease" ? (
          <FaArrowDownLong color="#F1595C" />
        ) : (
          ""
        )
      }
      compareText={`${
        percentageChange?.type === "increase"
          ? "Increased"
          : percentageChange?.type === "decrease"
          ? "Decreased"
          : "Increased"
      }`}
      description={
        <div>
          <p>
            $2,000 <span className="text-[#03022980]">(Upfront Payment)</span>
          </p>
          <p>
            $7,000 <span className="text-[#03022980]">(Other Intallment)</span>
          </p>
        </div>
      }
      btn={
        <CustomButton
          btnName={<p className="text-[10px] font-normal"> View Reports</p>}
          textColor={"[#605BFF]"}
          borderColor={"[#605BFF40]"}
        />
      }
    />
  );
};

export const ExpectedPaymentAmount = () => {
  const { overView, company } = useSelector((state: any) => state);
  const CurrentExpectedAmount =
    overView?.quantityRevenue?.data?.expectedAmountDay2;
  const ComparedExpectedAmount =
    overView?.quantityRevenue?.data?.expectedAmountDay1;
  const percentageChange = calculatePercentageChange(
    CurrentExpectedAmount,
    ComparedExpectedAmount
  );

  return (
    <OverViewCard
      title={"Total Due Amount"}
      amount={` ${getDefaultCurrencyCode(company)}  ${
        overView?.quantityRevenue?.data?.expectedAmountDay2 || 0
      }`}
      icon={<OverviewIcon />}
      comparedAmount={`${getDefaultCurrencyCode(company)} ${
        overView?.quantityRevenue?.data?.expectedAmountDay1 || 0
      }`}
      parcent={
        <p
          className={`${
            percentageChange?.type === "increase"
              ? "text-[#57C38A]"
              : percentageChange?.type === "decrease"
              ? "text-[#F1595C]"
              : "text-[#605BFF]"
          }`}
        >
          {" "}
          {`${percentageChange?.percentage}%`}
        </p>
      }
      textColor={"[#57C38A]"}
      icon2={
        percentageChange?.type === "increase" ? (
          <FaArrowUpLong color="#57C38A" />
        ) : percentageChange?.type === "decrease" ? (
          <FaArrowDownLong color="#F1595C" />
        ) : (
          ""
        )
      }
      compareText={`${
        percentageChange?.type === "increase"
          ? "Increased"
          : percentageChange?.type === "decrease"
          ? "Decreased"
          : "Increased"
      }`}
      description={<p>Revenue Forecast(Across all Installments)</p>}
    />
  );
};
