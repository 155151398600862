import React, { useEffect } from "react";
import { Card, Badge, Tag, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeliveryNoteAction } from "../../../../store/wareHouse/actions";
import { searchValue } from "../../../../utils/setColor";

const DeliveryComponent = () => {
  const { wareHouse, auth } = useSelector((state: any) => state);
  const values = wareHouse?.all?.data;

  const dispatch = useDispatch();
  useEffect(() => {
    if (auth?.token) {
      getAllDeliveryNoteAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);
  const navigate = useNavigate();

  const data =
    values?.map((el: any) => ({
      id: el._id,
      deliveryID: el.deliveryID,
      pOrderID: el.pOrderID.pOrderID,
      arrivalDate: el.arrivalDate,
      status: el?.status,
      data: el,
      qnt: el?.listOfItems?.reduce(
        (sum: number, a: any) => sum + a?.qtySent,
        0
      ),
    })) || [];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
      {wareHouse?.isFetching
        ? Array.from({ length: 5 }).map((_, index) => (
          <div className="bg-white w-full h-[174px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
            <Skeleton active paragraph={{ rows: 2 }} />
          </div>
        ))
        : data?.map((delivery: any) => (
          <Badge.Ribbon
            key={delivery.id}
            color={searchValue(delivery.status)}
            text={
              <span
                style={{
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  fontWeight: 400,
                  paddingTop: "1.5px",
                }}
              >
                {delivery.status}
              </span>
            }
            style={{ width: "9rem", padding: "4px 1px" }}
          >
            <Card
              title={delivery.deliveryID}
              onClick={() => navigate(`/deliveryOrder/${delivery.id}`)}
            >
              <p>{delivery.pOrderID}</p>
              <p>{delivery.pOrderID?.supplier?.name}</p>
              <div className="absolute right-6 top-10">
                {delivery?.data?.ebmRegistered && (
                  <Tag
                    color={"blue"}
                    className="text-center px-4 py-1 rounded-2xl text-xs"
                  >
                    SAVED IN EBM
                  </Tag>
                )}
              </div>
              <hr className="pb-2" />
              <div className="flex justify-between items-end w-full pt-8">
                <div>
                  <p className="text-sm text-[#030229] font-normal leading-normal">
                    {delivery?.data?.createdAt.slice(0, 10)}
                  </p>
                </div>
                <p className="text-xs text-gray-900 leading-normal font-medium pr-3">
                  <b>{delivery?.qnt} items</b>
                </p>
              </div>
            </Card>
          </Badge.Ribbon>
        ))}
    </div>
  );
};
export default DeliveryComponent;
