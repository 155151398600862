import React from "react";
import { SlEye } from "react-icons/sl";
import { Space, Tag } from "antd";

const ViewAction = (props: any) => {
  return (
    <Tag
      style={{
        fontSize: "15px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        width: "auto",
        minWidth: "100%",
        textAlign: "center",
      }}
      onClick={() => props?.onOpenViewModel()}
      className=" p-2"
    >
      <Space>
        <SlEye size={16} color="#2943D6" spacing={5} />
        <p style={{ fontSize: "14px" }}>View</p>
      </Space>
    </Tag>
  );
};
export default ViewAction;
