import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { searchValue } from "../../utils/setColor";
import SubscriptionDrower from "../Modals/SubscriptionDrower";
import { getAllSubRequestedAction } from "../../store/subscription/subscriptionRequest/actions";
import { customerValidationAction } from "../../store/customer/actions";

const SubscriptionRequestTable = (props: any) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const { auth, subscriptionRequest ,customer} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const showDrawer = (d: any) => {
    setOpen(true);
    setData(d);
    customerValidationAction(
      d?.data?.customer?._id,
      {},
      auth?.token
    )(dispatch);
  };

  const onClose = () => {
    setOpen(false);
  };
  const dataToDisplay = subscriptionRequest?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      date: el?.createdAt?.slice(0, 10),
      applicationid: el?.cartId,
      nid: el?.customer?.subscriptionInfo?.nationalIDDoc,
      installmemtPlan: el?.instalmentPlan,
      installmentPayment:
        el?.instalmentPlan === "monthly"
          ? el?.selectedPlan?.monthlyInstalment
          : el?.instalmentPlan === "weekly"
            ? el?.selectedPlan?.weeklyInstalment
            : el?.selectedPlan?.dailyInstalment,
      shop: el?.shop?.name,
      channel: el?.shop?.channel?.name,
      agent: el?.createdBy?.names,
      name: el?.customer?.name,
      phone: el?.customer?.phone,
      imei: el?.list[0]?.warehouseProduct?.serialNumber,
      model: el?.selectedPlan?.pricelistItem?.product?.model,
      brand: el?.selectedPlan?.pricelistItem?.product?.brand,
      plan: el?.subscriptionPlan,
      subscriptionType: el?.businessProduct?.name ?? "N/A",
      specification:
        el?.list[0]?.shopStockItem?.requestedItem?.product?.specification
          ?.slice(2, 10)
          ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
          ?.join(", "),
      unpaidAmount: el?.selectedPlan?.initialPayment.toLocaleString(),
      status: el?.status,
      data: el,
    };
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Channel Name",
      dataIndex: "channel",
      key: "channel",
      render: (text: any, record: any) => (
        <div>
          <p className="capitalize">{`${text}`}</p>
        </div>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Application ID",
      dataIndex: "applicationid",
      key: "applicationid",
    },
    {
      title: "Shop Name",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text: any, record: any) => (
        <div>
          <p className="capitalize">{`${text}`}</p>
          {/* <p className="capitalize">{`${record?.specification}`}</p> */}
        </div>
      ),
    },

    {
      title: "Upfront",
      dataIndex: "unpaidAmount",
      key: "unpaidAmount",
    },
    {
      title: "Installment Payment",
      dataIndex: "installmentPayment",
      key: "installmentPayment",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text?.toLocaleString()} ${record?.installmemtPlan
          }`}</p>
      ),
    },
    {
      title: "Subscription Period",
      dataIndex: "plan",
      key: "plan",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text} Months`}</p>
      ),
    },
    {
      title: "Business Product Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text}`}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <button
          style={{ background: searchValue(text) }}
          className=" text-[#03022980] font-normal py-1 px-4 rounded-md"
        >
          {text}
        </button>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <div className="flex items-center space-x-1 cursor-pointer">
          <button
            className="bg-white hover:bg-[#F5F6FA] text-[#030229] font-normal py-2 px-4 rounded-[5px] border border-[#03022925]"
            onClick={() => {
              showDrawer(record);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];
 
  useEffect(() => {
    auth?.token && getAllSubRequestedAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  const scroll = { x: true as const };

  return (
    <>
      <div className="scrollbar-thin">
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          scroll={scroll}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
          }}
          loading={subscriptionRequest?.isFetching}
          className="scrollbar-hide"
        />
      </div>
      <SubscriptionDrower open={open} onClose={onClose} data={data} />
    </>
  );
};
export default SubscriptionRequestTable;
