import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import { Stack } from "@mui/material";
import CustomerCard from "../../components/grids/CustomerGridCard";
import { useDispatch, useSelector } from "react-redux";
import AddNewCustomer from "../../components/forms/NewCustomer";
import { getAllCustomersAction } from "../../store/customer/actions";
import ScrollableFrame from "../../components/layout/ScrollableFrame";
import SearchInput from "../../components/buttons/SearchButton";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { Tag, Space, Table,Skeleton } from "antd";
import { searchValue } from "../../utils/setColor";
import type { TableColumnsType } from "antd";
import { myLayoutActions } from "../../store/layout";
import { useNavigate } from "react-router-dom";
import { customerIncludeItem } from "../../assets/data/includedItem";

interface DataType {
  key: React.Key;
  name: string;
  foto: string;
  id: any;
  email: string;
  phone: string;
  adress: string;
  status: any;
}

const PageView = () => {
  const { customer, auth, layout } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(25);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Space>
            <img
              className="w-6 h-6 rounded-full"
              src={record?.foto}
              alt="customer avatar"
            />
            <p> {text}</p>
          </Space>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "adress",
      key: "adress",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag color={searchValue(status)}>{status}</Tag>
          </>
        );
      },
    },
  ];
  const customerdata = customer?.all?.data?.map((el: any) => {
    return {
      key: el?._id,
      name: el?.name,
      foto: el?.picture,
      id: el?.nid,
      email: el?.email,
      phone: el?.phone,
      adress: el?.address,
      status: el?.status,
    };
  });

  const handleIconClick = (iconType: string) => {
    dispatch(myLayoutActions.setIsCustomertableview(iconType));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        customer?.query ||
          `?page=${page}&limit=${limit}&field=${customerIncludeItem}`
      )(dispatch);
  }, [
    auth?.token,
    customer.new,
    customer?.query,
    customer.updated,
    dispatch,
    limit,
    page,
  ]);

  useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        getAllCustomersAction(
          auth?.token,
          customer?.query ||
            `?page=${page}&limit=${limit}&field=${customerIncludeItem}`
        )(dispatch);
    }
  }, [searchKey]);

  const handleSearch = () => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        `?page=${page}&limit=${limit}&sk=${searchKey}&field=${customerIncludeItem}`
      )(dispatch);
  };

  return (
    <Stack spacing={1} style={{ marginTop: "1.5rem" }}>
      <div className="flex items-center justify-between px-6 mb-4 pr-12">
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />
        <div className="flex">
          <div className="text-gray-500 flex flex-row">
            <TbGridDots
              className={`cursor-pointer ${
                layout?.isCustomertableview === "grid view"
                  ? "text-blue-500"
                  : "text-gray-500"
              }`}
              size={30}
              onClick={() => handleIconClick("grid view")}
            />
            <AiOutlineUnorderedList
              size={30}
              onClick={() => handleIconClick("table view")}
              className={`cursor-pointer ${
                layout?.isCustomertableview === "table view"
                  ? "fill-blue-500"
                  : "fill-gray-500"
              }`}
            />
          </div>
          <HeaderComponent
            item="Customer"
            modelTitle="Add Customer"
            ModelComponent={
              <AddNewCustomer
                onCancel={handleCancel}
                limit={limit}
                page={page}
              />
            }
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleCancel={handleCancel}
          />
        </div>
      </div>
      {layout?.isCustomertableview === "grid view" && (
        <Stack spacing={1}>
          <ScrollableFrame
            loading={customer?.isFetching}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={customer?.all?.total}
            count={Math.ceil(customer?.all?.total / limit)}
          >
            <div
              style={{
                padding: "2px",
                display: "flex",
                gap: "1.5rem",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {customer?.all?.data?.map((cust: any) => (
                <>
                  {(customer?.allIsFetching || customer?.isFetching)? (
                    <div className="flex flex-wrap gap-4 justify-start items-start">
                      {Array.from({ length: 4 }).map(() => (
                        <div className="bg-white w-[362px] h-[160px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
                        <Skeleton active paragraph={{ rows: 2 }} />
                      </div>
                      ))}
                    </div>
                  ) : (
                    <CustomerCard data={cust} />
                  )}
                </>
              ))}
            </div>
          </ScrollableFrame>
        </Stack>
      )}
      {layout?.isCustomertableview === "table view" && (
        <div className="w-full">
          <ScrollableFrame
            loading={customer?.isFetching}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={customer?.all?.total}
            count={Math.ceil(customer?.all?.total / limit)}
          >
            <Table
              columns={columns}
              dataSource={customerdata}
              pagination={false}
              loading={customer?.isFetching}
              onRow={(record) => ({
                onClick: () => navigate(`/customers/${record?.key}`),
              })}
              scroll={{ y: 750 }}
            />
          </ScrollableFrame>
        </div>
      )}
    </Stack>
  );
};
export default PageView;
