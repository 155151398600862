import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import PurchaseOrderGrid from "../../../components/grids/PurchaseOrderGrid";
import { getAllPurchaseOrdersAction } from "../../../store/purchase/actions";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { PurchaseOrdersHeaders } from "../../../components/csvHeaders/Headers";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import FilesCard from "../../../components/cards/NoSale/FilesCard";
import { VscFilePdf } from "react-icons/vsc";
import { getAllPO_CSVAction } from "../../../store/csvDownload/actions";
import { purchaseIncludeItem } from "../../../assets/data/includedItem";
import { Skeleton } from "antd";
const PurchaseOrder = () => {
  const { auth, purchase, layout, CSV } = useSelector((state: any) => state);
  const [purchaseOrders, setPurchaseOrders] = React.useState<any>([]);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(30);

  React.useEffect(() => {
    auth?.token &&
      getAllPurchaseOrdersAction(
        auth?.token,
        `page=${page - 1}&limit=${limit}&field=${purchaseIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllPO_CSVAction(
          auth?.token,
          `limit=${purchase?.all?.total}&page=0`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  useEffect(() => {
    if (purchase?.all?.data) {
      const updatedPurchaseOrders = purchase?.all?.data?.map((quot: any) => {
        return {
          _id: quot?._id,
          supplier: quot?.supplier?.name,
          poId: quot?.pOrderID,
          poStatus: quot?.status,
          totalAmount: quot?.listOfProducts
            ?.map((product: any) => product?.quantity * product?.priceUnit)
            .reduce((sum: number, a: number) => sum + a),
          deliveryDeadline: quot?.createdAt?.split("T")[0],
          data: quot,
        };
      });
      setPurchaseOrders(updatedPurchaseOrders);
    }
  }, [purchase?.all, setPurchaseOrders]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    date: record?.createdAt?.slice(0, 10),
    supplier: record.supplier?.name || "N/A",
    items: record?.listOfProducts[0]?.quotationItem?.product?.model || "N/A",
    quantity: record?.listOfProducts[0]?.quantity?.toLocaleString(),
    cost: parseInt(record?.listOfProducts[0]?.priceUnit)?.toLocaleString(),
    totalCost: record?.listOfProducts
      ?.map((product: any) => product?.quantity * product?.priceUnit)
      .reduce((sum: number, a: number) => sum + a)
      .toLocaleString(),
    deliveryDate: record?.createdAt?.split("T")[0],
  }));

  return (
    <Stack spacing={1}>
      <div className="text-[#030229] opacity-80  font-normal pt-2 pb-2 flex justify-between items-center pl-4 ">
        <DetailsHeaderActionBar pageName="Purchase Order" title="All" />
        <div className="flex gap-4 mt-4">
          <FilesCard
            Icon={VscFilePdf}
            tailwindTextColor="text-white"
            tailwindBackgroundColor="bg-orange-400"
            disabled
          />
          <CSVExportButton
            csvHeaders={PurchaseOrdersHeaders()}
            csvData={csvData}
            filename={`Purchase Order Statistics.csv`}
          />
        </div>
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={purchase?.all?.total}
        count={Math.ceil(purchase?.all?.total / limit)}
      >
        <div className="flex flex-wrap gap-8 justify-start items-start">
          {purchase?.isFetching ? (
            <div className="flex flex-wrap gap-8 justify-start items-start">
              {Array.from({ length: 8 })?.map((i: any) => (
                <div className="bg-white w-[360px] h-[154px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
                  <Skeleton active paragraph={{ rows: 2 }} />
                </div>
              ))}
            </div>
          ) : (
            <PurchaseOrderGrid data={purchaseOrders} />
          )}
        </div>
      </ScrollableFrame>
    </Stack>
  );
};
export default PurchaseOrder;
