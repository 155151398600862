import React from "react";
import { DatePicker, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllNosaleAction } from "../../store/pos/actions";
import ScrollableFrame from "../layout/ScrollableFrame";
import { NosalesHeaders } from "../csvHeaders/Headers";
import { getActivePrice } from "../../utils/converter";
import dayjs from "dayjs";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import type { TimeRangePickerProps } from "antd";
import type { Dayjs } from "dayjs";
import NSQuickCard from "../cards/NoSale/NSQuickCard";
import { VscFilePdf } from "react-icons/vsc";
import FilesCard from "../cards/NoSale/FilesCard";
import CSVExportButton from "../cards/NoSale/CSVExportButton";
import {
  searchCardBgColorValue,
  searchCardDeepBgColorValue,
} from "../../utils/setColor";
import { getAllNoSales_CSVAction } from "../../store/csvDownload/actions";
import { getDefaultCurrencyCode } from "../../utils/helper";

const { RangePicker } = DatePicker;

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

// const shopId =
const NosaleTable = (props: any) => {
  const isTablet = useMediaQuery("(max-width: 1100px)");
  const { pos, auth, layout, CSV, company } = useSelector(
    (state: any) => state
  );
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(7, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);
  console.log("From: ", startDate, ", to: ", endDate);

  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (_text, record) => (
        <p>
          {record?.data?.priceListItemId?.product?.model ||
            record?.data?.newProduct ||
            record?.data?.productId?.model}
        </p>
      ),
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
      render: (text, record) => (
        <p>
          {(record?.data?.priceListItemId
            ? text
            : record?.data?.productId?.specs
                ?.slice(2)
                ?.map((d: any) => `${d?.label} : ${d?.value}`)
                ?.join("; ")) ?? "N/A"}
        </p>
      ),
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "prices",
      key: "prices",
      render: (text, _record) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },

    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, _record) => <p>{text || "-"}</p>,
    },
    {
      title: "Reason",
      dataIndex: "comments",
      key: "comments",
      render: (text, record) => <p>{text || "N/A"}</p>,
    },
  ];

  React.useEffect(() => {
    if (startDate || endDate) {
      auth?.token &&
        getAllNosaleAction(
          auth?.token,
          `?limit=${limit}&page=${
            page - 1
          }&startingDate=${startDate}&endingDate=${endDate}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllNosaleAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}`
        )(dispatch);
    }
  }, [auth.token, dispatch, limit, page, startDate, endDate]);

  React.useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllNoSales_CSVAction(
          auth?.token,
          `?limit=${pos?.nosale?.total}&page=0&startingDate=${startDate}&endingDate=${endDate}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const value = pos?.nosale?.data?.noSales;
  const noSaleComments = pos?.nosale?.data?.comments;

  const dataToDisplay = value?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      agent: el?.createdBy?.names,
      specification: `${el?.priceListItemId?.specification
        ?.slice(2)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      barCode: el?.warehouseProduct?.serialNumber,
      shop: `${el?.shopId?.name}`,
      type: el?.shopId?.type,
      channel: el?.shopId?.channel?.name,
      customer: el?.customerId?.name,
      comments: el?.comment,
      quantity: el?.quantity,
      prices: getActivePrice(el?.priceListItemId?.prices)?.toLocaleString(),
      data: el,
    };
  });

  const dataToDownload = CSV?.csvDownloadedData?.data?.noSales?.map(
    (el: any) => {
      return {
        date: el?.createdAt?.slice(0, 10),
        agent: el?.createdBy?.names,
        specification: `${el?.priceListItemId?.specification
          ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
          ?.join(", ")}`,
        barCode: el?.warehouseProduct?.serialNumber,
        shop: `${el?.shopId?.name} ~ ${el?.shopId?.type}`,
        type: el?.shopId?.type,
        channel: el?.shopId?.channel?.name,
        customer: el?.customerId?.name,
        comments: el?.comment,
        quantity: el?.quantity,
        prices: getActivePrice(el?.priceListItemId?.prices)?.toLocaleString(),
        data: el,
      };
    }
  );

  const csvData = dataToDownload?.map((record: any) => ({
    date: record.date,
    model:
      record?.data?.priceListItemId?.product?.model ||
      record?.data?.newProduct ||
      record?.data?.productId?.model,
    specification: record?.data?.priceListItemId
      ? record?.specification
      : "N/A",
    storage: record?.data?.priceListItemId?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
      .map((item: any) => item[1]),
    memory: record?.data?.priceListItemId?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
      .map((item: any) => item[1]),
    prices: record?.prices || "N/A",
    quantity: record.quantity,
    shop:
      record.data?.shopId?.type !== "service-center"
        ? record.data?.shopId?.name +
          "-" +
          record.data?.shopId?.type?.split("-")?.join(" ")
        : record.data?.shopId?.name,
    agent: record?.agent,
    customer: record?.data?.customerId?.name || "-",
    customerTel: record?.data?.customerId?.phone || "-",
    comments: record?.comments ? record?.comments : "N/A",
  }));

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  return (
    <div className="relative bg-white py-4 rounded-lg">
      <div className="flex items-center justify-between mx-4 mt-4">
        <div>
          <p className="text-2xl">No Sale details</p>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-4">
            <span>Select Date:</span>
          </div>
          <RangePicker presets={rangePresets} onChange={onRangeChange} />
        </div>
      </div>
      <div
        className={`flex ${
          noSaleComments?.length < 5 ? " justify-end " : " justify-between "
        } flex-wrap p-4 gap-3`}
      >
        {noSaleComments &&
          false &&
          noSaleComments?.map((item: any, index: number) => (
            <NSQuickCard
              name={item?.comment}
              amount={item?.totalAmount}
              percentage={item?.count}
              bgColor={searchCardBgColorValue(item?.comment)}
              deepBgColor={searchCardDeepBgColorValue(item?.comment)}
              size={noSaleComments?.length}
            />
          ))}
      </div>
      <div className="flex justify-end gap-4 p-4">
        <FilesCard
          Icon={VscFilePdf}
          tailwindTextColor="text-white"
          tailwindBackgroundColor="bg-orange-400"
          disabled
        />
        <CSVExportButton
          csvHeaders={NosalesHeaders()}
          csvData={csvData}
          filename={`No-Sales-from-${startDate + "-to-" + endDate}.csv`}
        />
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.nosale?.total}
        count={Math.ceil(pos?.nosale?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          pagination={false}
          loading={pos?.isFetching}
          // scroll={{ y: "calc(100vh - 335px)" }}
          scroll={isTablet ? { x: 1300 } : undefined}
        />
      </ScrollableFrame>
    </div>
  );
};

export default NosaleTable;
